@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: "General Sans", sans-serif;
}

.maskedDiv {
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 12.5%, rgb(0, 0, 0) 87.5%, rgba(0, 0, 0, 0) 100%);
}

.linearText {
    color: linear-gradient(90deg, #FED98F 0%, #FFCA5F 100%);
}

.linearBg {
    background: linear-gradient(90deg, #FED98F 0%, #FFCA5F 100%);
}

.borderLinear {
    border: 1px solid linear-gradient(90deg, #FED98F 0%, #FFCA5F 100%);
}

.linearBgLight {
    background: linear-gradient(90deg, rgba(254, 217, 143, 0.05) 0%, rgba(255, 202, 95, 0.05) 100%);
}

.no-scrollbar {
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}